<template>
    <div>
        <h2 class="mb-1">Notification Details</h2>

        <b-row class="mt-2 mb-2">
            <b-list-group class="w-100">
                <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                        Clinician Name: <span style="color: #656b85; font-weight: initial;">{{ dataClinician.name }}</span>
                        </h5>
                    </div>
                    <b-card-text class="mb-1">
                        <p><b>Malpractice insurance expiration date: </b>{{ dataClinician.malpracticeInsuranceExpirationDateTrack }}</p>
                        <p><b>License expiration date: </b>{{ dataClinician.licenseExpirationDateTrack }}</p>
                        <p><b>Expiration date: </b>{{ dataClinician.expirationDateTrack }}</p>
                    </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </b-row>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,
            isClinician: '',
            fieldsTable: [
                { key: 'date', label: 'Date', sortable: false },
                { key: 'description', label: 'Description', sortable: false },
            ],
            arrayNotifications: [],
            dataClinician: {},

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',
            insuranceRates: '',
            didClientProvideCopay: '',
            didclientUtilizeSlidingScale: '',
            claimDateMonth: '',
            claimDateYear: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],
            counterInsuranceUtilizedForSession: 0,
            counterInsuranceReimbursementAmount: 0,
            counterclientsThatProvidedCopay: 0,
            counterAverageofCopay: 0,
            counterTotalSlidingScale: 0,
            counterAmountofSlidingScale: 0,
            counterClientDoesntHaveInsurance: 0,
            counterDeductibleNotMet: 0,
            counterDontAcceptClientsInsurance: 0,
            counterInsuranceDoesNotCoverTelehealthServices: 0,
            counterOther: 0,

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],
            optionsConnected: ['Yes', 'No'],
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            reasonWhyClientWasReferredToClinician: '',
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Cost of deductible',
                'Doesn’t want employer to find out',
                'Doesn’t want family member to find out',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],
            optionsClaimDateMonth: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            optionsClaimDateYear: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: false,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: true,
            firstLoadData: true,
            seenClients: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        BListGroup,
        BListGroupItem,
        BCardText,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created() {
        this.firstLoadDOM = false
        this.firstLoadData = false
        this.readyToExport = true
        this.isBusy = false
        this.isClinician = ''

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            this.isClinician = ''
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }

            if(doc.data().role === 'clinician') {
                this.isClinician = doc.id
                this.clinicianName = {
                    uid: doc.id,
                    name: doc.data().firstName + ' ' + doc.data().lastName
                }
            }
        })

        // console.log(this.$route.params.id)
        db.collection('usuarios').doc(this.$route.params.id).get()
        .then(doc => {
            this.dataClinician = {
                name: doc.data().nombre,
                malpracticeInsuranceExpirationDateTrack: doc.data().malpracticeInsuranceExpirationDateTrack,
                expirationDateTrack: doc.data().expirationDateTrack,
                licenseExpirationDateTrack: doc.data().licenseExpirationDateTrack,
            }
        })
    }
}
</script>

<style>
.clinicianReportTable thead tr th {
    text-transform: none !important;
}
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>